$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);


@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Custom icons
ion-icon {
    &[class*="udaf-"] {
        mask-size: contain;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        background: currentColor;
        width: 1em;
        height: 1em;
    }
    // custom icons
    //&[class*="udaf-loisirs"] {
    //    mask-image: url(../assets/img/loisirs.svg);
    //}
    &[class*="udaf-add"] {
        mask-image: url(../assets/img/add.svg);
    }
    &[class*="udaf-remove"] {
        mask-image: url(../assets/img/remove.svg);
    }
}

.card-img {
	img {
		width: 100%;
		height: auto;
	}
}

ion-menu {
	ion-icon {
	    &[class*="udaf-"] {
	        width: auto;
	        height: auto;
	    }
    }
}

form {
	ion-item.item-block .item-inner {
	    -webkit-box-shadow: inset 0 -1px 0 0 var(--ion-color-gris2);
	    box-shadow: inset 0 -1px 0 0 var(--ion-color-gris2);
	}
	.item-checkbox.item-block {
		.item-inner {
		    -webkit-box-shadow: none;
		    box-shadow: none;
		}
	}
}

.accordeon-title {
    background-color: var(--ion-color-gris0);
    transition: all 0.5s ease-in-out;
    font-weight: bold;

    ion-icon {
	    float: right;
    }

	&.active {
		background-color: var(--ion-color-gris2);
	}
}

.card-astuces, .card-attention {
	a {
		color: white;
		font-weight: 700;
		text-decoration: underline;
	}
}

.card-subtitle, .card-ios h3, .card-md h3 {
	font-size: 1.4rem;
	font-weight: 700;
	margin-top: 1.6rem;
    margin-bottom: 1rem;
}

.accordeon-content {
    display: none;
	transition: all 0.5s ease-in-out;
}

.username {
	color: var(--ion-color-gris3);
}

.astuces {
	h2, p {
		font-weight: bold;
	}
	h2 {
		text-transform: uppercase;
	}
}

h2.card-title {
	color: var(--ion-color-primary);
	font-weight: 700;
}

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}


// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.1.
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

// Creates text alignment classes based on screen size
// TODO this would be nice to add to the framework
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    // Provide text `[text-{bp}]` attributes for aligning text based on screen size
    [text#{$infix}-center] {
      text-align: center;
    }

    [text#{$infix}-left] {
      text-align: left;
    }

    [text#{$infix}-right] {
      text-align: right;
    }

    [hidden#{$infix}-up] {
      display: none;
    }
  }

  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    [hidden#{$infix}-down] {
      display: none;
    }
  }
}

.color-menu {
    ion-label {
        font-size: 12px;
        color: #333333;
        text-transform: uppercase;
        margin: 11px 8px 11px 0;
    }
}
