// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/v2/theming/

// Font path is used to include ionicons,
// roboto, and noto sans fonts
$font-path: "../assets/fonts";


// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
$app-direction: ltr;


// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/v2/theming/overriding-ionic-variables/

$headings-font-weight: 300;

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    src: url('../assets/fonts/Nunito-Black.ttf');
}

$title-font: 'Nunito', sans-serif;

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

:root {
    --ion-color-gris0: #f5f5f5;
    --ion-color-gris0-rgb: 245,245,245;
    --ion-color-gris0-contrast: #000000;
    --ion-color-gris0-contrast-rgb: 0,0,0;
    --ion-color-gris0-shade: #d8d8d8;
    --ion-color-gris0-tint: #f6f6f6;
}

.ion-color-gris0 {
    --ion-color-base: var(--ion-color-gris0);
    --ion-color-base-rgb: var(--ion-color-gris0-rgb);
    --ion-color-contrast: var(--ion-color-gris0-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gris0-contrast-rgb);
    --ion-color-shade: var(--ion-color-gris0-shade);
    --ion-color-tint: var(--ion-color-gris0-tint);
}
:root {
    --ion-color-gris1: #eeeeee;
    --ion-color-gris1-rgb: 238,238,238;
    --ion-color-gris1-contrast: #000000;
    --ion-color-gris1-contrast-rgb: 0,0,0;
    --ion-color-gris1-shade: #d1d1d1;
    --ion-color-gris1-tint: #f0f0f0;
}

.ion-color-gris1 {
    --ion-color-base: var(--ion-color-gris1);
    --ion-color-base-rgb: var(--ion-color-gris1-rgb);
    --ion-color-contrast: var(--ion-color-gris1-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gris1-contrast-rgb);
    --ion-color-shade: var(--ion-color-gris1-shade);
    --ion-color-tint: var(--ion-color-gris1-tint);
}
:root {
    --ion-color-gris2: #e0e0e0;
    --ion-color-gris2-rgb: 224,224,224;
    --ion-color-gris2-contrast: #000000;
    --ion-color-gris2-contrast-rgb: 0,0,0;
    --ion-color-gris2-shade: #c5c5c5;
    --ion-color-gris2-tint: #e3e3e3;
}

.ion-color-gris2 {
    --ion-color-base: var(--ion-color-gris2);
    --ion-color-base-rgb: var(--ion-color-gris2-rgb);
    --ion-color-contrast: var(--ion-color-gris2-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gris2-contrast-rgb);
    --ion-color-shade: var(--ion-color-gris2-shade);
    --ion-color-tint: var(--ion-color-gris2-tint);
}
:root {
    --ion-color-gris3: #9e9e9e;
    --ion-color-gris3-rgb: 158,158,158;
    --ion-color-gris3-contrast: #000000;
    --ion-color-gris3-contrast-rgb: 0,0,0;
    --ion-color-gris3-shade: #8b8b8b;
    --ion-color-gris3-tint: #a8a8a8;
}

.ion-color-gris3 {
    --ion-color-base: var(--ion-color-gris3);
    --ion-color-base-rgb: var(--ion-color-gris3-rgb);
    --ion-color-contrast: var(--ion-color-gris3-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gris3-contrast-rgb);
    --ion-color-shade: var(--ion-color-gris3-shade);
    --ion-color-tint: var(--ion-color-gris3-tint);
}
:root {
    --ion-color-gris4: #424242;
    --ion-color-gris4-rgb: 66,66,66;
    --ion-color-gris4-contrast: #ffffff;
    --ion-color-gris4-contrast-rgb: 255,255,255;
    --ion-color-gris4-shade: #3a3a3a;
    --ion-color-gris4-tint: #555555;
}

.ion-color-gris4 {
    --ion-color-base: var(--ion-color-gris4);
    --ion-color-base-rgb: var(--ion-color-gris4-rgb);
    --ion-color-contrast: var(--ion-color-gris4-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gris4-contrast-rgb);
    --ion-color-shade: var(--ion-color-gris4-shade);
    --ion-color-tint: var(--ion-color-gris4-tint);
}

:root {
    --ion-color-primary: #488aff;
    --ion-color-primary-rgb: 72,138,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #3f79e0;
    --ion-color-primary-tint: #5a96ff;
}

.ion-color-primary, .ion-color-budget, .ion-color-ui {
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
}

:root {
    --ion-color-secondary: #32db64;
    --ion-color-secondary-rgb: 50,219,100;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #2cc158;
    --ion-color-secondary-tint: #47df74;
}

.ion-color-secondary {
    --ion-color-base: var(--ion-color-secondary);
    --ion-color-base-rgb: var(--ion-color-secondary-rgb);
    --ion-color-contrast: var(--ion-color-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-shade);
    --ion-color-tint: var(--ion-color-secondary-tint);
}

:root {
    --ion-color-danger: #f53d3d;
    --ion-color-danger-rgb: 245,61,61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #d83636;
    --ion-color-danger-tint: #f65050;
}

.ion-color-danger {
    --ion-color-base: var(--ion-color-danger);
    --ion-color-base-rgb: var(--ion-color-danger-rgb);
    --ion-color-contrast: var(--ion-color-danger-contrast);
    --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
    --ion-color-shade: var(--ion-color-danger-shade);
    --ion-color-tint: var(--ion-color-danger-tint);
}

:root {
    --ion-color-light: #f4f4f4;
    --ion-color-light-rgb: 244,244,244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d7d7;
    --ion-color-light-tint: #f5f5f5;
}

.ion-color-light {
    --ion-color-base: var(--ion-color-light);
    --ion-color-base-rgb: var(--ion-color-light-rgb);
    --ion-color-contrast: var(--ion-color-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-shade);
    --ion-color-tint: var(--ion-color-light-tint);
}

:root {
    --ion-color-attention: #c51162;
    --ion-color-attention-rgb: 197,17,98;
    --ion-color-attention-contrast: #ffffff;
    --ion-color-attention-contrast-rgb: 255,255,255;
    --ion-color-attention-shade: #ad0f56;
    --ion-color-attention-tint: #cb2972;
}

.ion-color-attention {
    --ion-color-base: var(--ion-color-attention);
    --ion-color-base-rgb: var(--ion-color-attention-rgb);
    --ion-color-contrast: var(--ion-color-attention-contrast);
    --ion-color-contrast-rgb: var(--ion-color-attention-contrast-rgb);
    --ion-color-shade: var(--ion-color-attention-shade);
    --ion-color-tint: var(--ion-color-attention-tint);
}

:root {
    --ion-color-astuces: #00B8D4;
    --ion-color-astuces-rgb: 0,184,212;
    --ion-color-astuces-contrast: #ffffff;
    --ion-color-astuces-contrast-rgb: 0,0,0;
    --ion-color-astuces-shade: #00a2bb;
    --ion-color-astuces-tint: #1abfd8;
}

.ion-color-astuces {
    --ion-color-base: var(--ion-color-astuces);
    --ion-color-base-rgb: var(--ion-color-astuces-rgb);
    --ion-color-contrast: var(--ion-color-astuces-contrast);
    --ion-color-contrast-rgb: var(--ion-color-astuces-contrast-rgb);
    --ion-color-shade: var(--ion-color-astuces-shade);
    --ion-color-tint: var(--ion-color-astuces-tint);
}

:root {
    --ion-color-loisirs: #FDD835;
    --ion-color-loisirs-rgb: 253,216,53;
    --ion-color-loisirs-contrast: #000000;
    --ion-color-loisirs-contrast-rgb: 0,0,0;
    --ion-color-loisirs-shade: #dfbe2f;
    --ion-color-loisirs-tint: #fddc49;
}

.ion-color-loisirs {
    --ion-color-base: var(--ion-color-loisirs);
    --ion-color-base-rgb: var(--ion-color-loisirs-rgb);
    --ion-color-contrast: var(--ion-color-loisirs-contrast);
    --ion-color-contrast-rgb: var(--ion-color-loisirs-contrast-rgb);
    --ion-color-shade: var(--ion-color-loisirs-shade);
    --ion-color-tint: var(--ion-color-loisirs-tint);
}

:root {
    --ion-color-sante: red;
    --ion-color-sante-rgb: 244,67,54;
    --ion-color-sante-contrast: #ffffff;
    --ion-color-sante-contrast-rgb: 255,255,255;
    --ion-color-sante-shade: #d73b30;
    --ion-color-sante-tint: #f5564a;
}

.ion-color-sante {
    --ion-color-base: var(--ion-color-sante);
    --ion-color-base-rgb: var(--ion-color-sante-rgb);
    --ion-color-contrast: var(--ion-color-sante-contrast);
    --ion-color-contrast-rgb: var(--ion-color-sante-contrast-rgb);
    --ion-color-shade: var(--ion-color-sante-shade);
    --ion-color-tint: var(--ion-color-sante-tint);
}

:root {
    --ion-color-transport: #FFA000;
    --ion-color-transport-rgb: 255,160,0;
    --ion-color-transport-contrast: #ffffff;
    --ion-color-transport-contrast-rgb: 0,0,0;
    --ion-color-transport-shade: #e08d00;
    --ion-color-transport-tint: #ffaa1a;
}

.ion-color-transport {
    --ion-color-base: var(--ion-color-transport);
    --ion-color-base-rgb: var(--ion-color-transport-rgb);
    --ion-color-contrast: var(--ion-color-transport-contrast);
    --ion-color-contrast-rgb: var(--ion-color-transport-contrast-rgb);
    --ion-color-shade: var(--ion-color-transport-shade);
    --ion-color-tint: var(--ion-color-transport-tint);
}

:root {
    --ion-color-communication: #9C27B0;
    --ion-color-communication-rgb: 156,39,176;
    --ion-color-communication-contrast: #ffffff;
    --ion-color-communication-contrast-rgb: 255,255,255;
    --ion-color-communication-shade: #89229b;
    --ion-color-communication-tint: #a63db8;
}

.ion-color-communication {
    --ion-color-base: var(--ion-color-communication);
    --ion-color-base-rgb: var(--ion-color-communication-rgb);
    --ion-color-contrast: var(--ion-color-communication-contrast);
    --ion-color-contrast-rgb: var(--ion-color-communication-contrast-rgb);
    --ion-color-shade: var(--ion-color-communication-shade);
    --ion-color-tint: var(--ion-color-communication-tint);
}

:root {
    --ion-color-logement: #8BC34A;
    --ion-color-logement-rgb: 139,195,74;
    --ion-color-logement-contrast: #ffffff;
    --ion-color-logement-contrast-rgb: 0,0,0;
    --ion-color-logement-shade: #7aac41;
    --ion-color-logement-tint: #97c95c;
}

.ion-color-logement {
    --ion-color-base: var(--ion-color-logement);
    --ion-color-base-rgb: var(--ion-color-logement-rgb);
    --ion-color-contrast: var(--ion-color-logement-contrast);
    --ion-color-contrast-rgb: var(--ion-color-logement-contrast-rgb);
    --ion-color-shade: var(--ion-color-logement-shade);
    --ion-color-tint: var(--ion-color-logement-tint);
}

:root {
    --ion-color-dark: #222;
    --ion-color-dark-rgb: 34,34,34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e1e1e;
    --ion-color-dark-tint: #383838;
}

.ion-color-dark {
    --ion-color-base: var(--ion-color-dark);
    --ion-color-base-rgb: var(--ion-color-dark-rgb);
    --ion-color-contrast: var(--ion-color-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-dark-shade);
    --ion-color-tint: var(--ion-color-dark-tint);
}

:root {
    --ion-color-favorite: #69BB7B;
    --ion-color-favorite-rgb: 105,187,123;
    --ion-color-favorite-contrast: #000000;
    --ion-color-favorite-contrast-rgb: 0,0,0;
    --ion-color-favorite-shade: #5ca56c;
    --ion-color-favorite-tint: #78c288;
}

.ion-color-favorite {
    --ion-color-base: var(--ion-color-favorite);
    --ion-color-base-rgb: var(--ion-color-favorite-rgb);
    --ion-color-contrast: var(--ion-color-favorite-contrast);
    --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
    --ion-color-shade: var(--ion-color-favorite-shade);
    --ion-color-tint: var(--ion-color-favorite-tint);
}

:root {
    --ion-color-twitter: #1da1f4;
    --ion-color-twitter-rgb: 29,161,244;
    --ion-color-twitter-contrast: #000000;
    --ion-color-twitter-contrast-rgb: 0,0,0;
    --ion-color-twitter-shade: #1a8ed7;
    --ion-color-twitter-tint: #34aaf5;
}

.ion-color-twitter {
    --ion-color-base: var(--ion-color-twitter);
    --ion-color-base-rgb: var(--ion-color-twitter-rgb);
    --ion-color-contrast: var(--ion-color-twitter-contrast);
    --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
    --ion-color-shade: var(--ion-color-twitter-shade);
    --ion-color-tint: var(--ion-color-twitter-tint);
}

:root {
    --ion-color-google: #dc4a38;
    --ion-color-google-rgb: 220,74,56;
    --ion-color-google-contrast: #ffffff;
    --ion-color-google-contrast-rgb: 255,255,255;
    --ion-color-google-shade: #c24131;
    --ion-color-google-tint: #e05c4c;
}

.ion-color-google {
    --ion-color-base: var(--ion-color-google);
    --ion-color-base-rgb: var(--ion-color-google-rgb);
    --ion-color-contrast: var(--ion-color-google-contrast);
    --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
    --ion-color-shade: var(--ion-color-google-shade);
    --ion-color-tint: var(--ion-color-google-tint);
}

:root {
    --ion-color-vimeo: #23b6ea;
    --ion-color-vimeo-rgb: 35,182,234;
    --ion-color-vimeo-contrast: #000000;
    --ion-color-vimeo-contrast-rgb: 0,0,0;
    --ion-color-vimeo-shade: #1fa0ce;
    --ion-color-vimeo-tint: #39bdec;
}

.ion-color-vimeo {
    --ion-color-base: var(--ion-color-vimeo);
    --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
    --ion-color-contrast: var(--ion-color-vimeo-contrast);
    --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
    --ion-color-shade: var(--ion-color-vimeo-shade);
    --ion-color-tint: var(--ion-color-vimeo-tint);
}

:root {
    --ion-color-facebook: #3b5998;
    --ion-color-facebook-rgb: 59,89,152;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255,255,255;
    --ion-color-facebook-shade: #344e86;
    --ion-color-facebook-tint: #4f6aa2;
}

.ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook);
    --ion-color-base-rgb: var(--ion-color-facebook-rgb);
    --ion-color-contrast: var(--ion-color-facebook-contrast);
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
    --ion-color-shade: var(--ion-color-facebook-shade);
    --ion-color-tint: var(--ion-color-facebook-tint);
}

ion-card-title {
    font-family: $title-font;
}

.card-img {
    background-color: var(--ion-color-primary);
}

$card-ios-text-color: inherit;
$card-md-text-color: inherit;
$card-wp-text-color: inherit;
$label-md-text-color-focused: inherit;
$label-wp-text-color-focused: inherit;

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here


// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here

// Use the primary color as the background for the toolbar-md-background
$toolbar-md-background: var(--ion-color-primary);

// Change the color of the segment button text
$toolbar-md-active-color: #fff;

// App Windows Variables
// --------------------------------------------------
// Windows only Sass variables can go here


// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

//@import "~@ionic/ionic.theme.default";


// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/v2/ionicons/

// Fonts
// --------------------------------------------------
// Roboto font is used by default for Material Design. Noto sans
// is used by default for Windows.

//@import "roboto";
//@import "noto-sans";
